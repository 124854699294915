<template>
  <div class="wazzup-container">
    <div class="wazzup-header">
      <h1>Мессенджеры</h1>
    </div>
    <div class="wazzup-content">
      <iframe
        :src="wazzup.url"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
import StatisticsService from '@/app/Services/StatisticsService'

export default {
  data() {
    return {
      wazzup: '',
    }
  },
  methods: {
    async getWazzup() {
      const res = await StatisticsService.getWazzup()
      this.wazzup = res.data
    },
  },
  mounted() {
    this.getWazzup()
  },
}
</script>
<style scoped>
.wazzup-container iframe {
  height: 100%;
  height: 80vh;
  width: 1200px !important;
  width: 100%;
}
.wazzup-container {
  widows: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
</style>
