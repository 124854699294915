import api from '@/app/api'

export default {

  async getWazzup() {
    const response = await api.get('/wazzup/whatsapp-frame-link')
    return response
  },

  async getStatisticsClient(client) {
    const response = await api.get('/statistic/client', client)
    return response
  },

  async getStatisticsDaily() {
    const response = await api.get('/statistic/daily')
    return response
  },

  async getStatisticsOrder() {
    const response = await api.get('/statistic/day-order')
    return response
  },

  async getStatisticsMostOrder() {
    const response = await api.get('/statistic/most-ordered')
    return response
  },
}
